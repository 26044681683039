import React from 'react'
import LimitlessCard from './LimitlessCard'
import ReadyToWear from './ReadyToWear'
import MoveAndRelax from './MoveAndRelax'
import LadyCard from './LadyCard'
import ForYou from './ForYouCard'
import RamadhaniyaCard from './Ramadhaniya'
import Azadea from './AzadeaCard'
import BTS from "./BTSCard"

const CardsSection = () => {
  return (
    <>
      <LimitlessCard id='limitless'></LimitlessCard>
      <ReadyToWear id='readytowear'></ReadyToWear>
      <MoveAndRelax id='moveandrelax'></MoveAndRelax>
      <BTS></BTS>
      <Azadea id='azadea'></Azadea>
      <RamadhaniyaCard id='ramadhaniya'></RamadhaniyaCard>
      <LadyCard id='lady'></LadyCard>
      <ForYou id='foryou'></ForYou>
    </>
    

  )
}

export default CardsSection
